<!--  -->
<template>
  <div class="classify baseMap">
    <div class="types">
      <div
        :class="{ type: true, active: activeSub == item.code }"
        v-for="item in subArr"
        :key="item.code"
        @click="changeSub(item)"
      >
        {{ item.name }}
      </div>
    </div>
    <van-tabs
      class="m_types"
      v-model="activeSub"
      color="#3ba86f"
      background="transparent"
      @change="mSubChange"
    >
      <van-tab
        :title="item.name"
        :name="item.code"
        v-for="item in subArr"
        :key="item.code"
      ></van-tab>
    </van-tabs>
    <div class="content">
      <div class="list-box" v-if="list.length > 0">
        <div class="list">
          <div
            class="item"
            v-for="item in list"
            :key="item.id"
            @click="getDetail(item)"
          >
            <div class="post">
              <img :src="URL + item.imgUrl" :alt="item.title" />
            </div>
            <div class="info">
              <div class="title">{{ item.title }}</div>
              <div class="subTitle">{{ item.subTitle }}</div>
              <div class="in">{{ item.introduce }}</div>
              <div class="more">查看详情</div>
            </div>
          </div>
        </div>
        <el-empty
          description="暂无数据"
          :image-size="200"
          v-show="list.length == 0"
        ></el-empty>
        <el-pagination
          :hide-on-single-page="total <= 9"
          @current-change="currentChange"
          :current-page.sync="queryParams.pageNum"
          :page-size="queryParams.pageSize"
          layout="total, prev, pager, next"
          :total="total"
        >
        </el-pagination>
      </div>

      <div class="rich" v-else>
        <div class="detail" v-show="!!list[0]"></div>
        <el-empty
          description="暂无数据"
          :image-size="200"
          v-show="list.length == 0"
        ></el-empty>
      </div>
    </div>
  </div>
</template>

<script>
import { URL } from "@/util/config.js";

export default {
  data() {
    return {
      URL: URL,
      subArr: [
        {
          name: "云上展厅",
          code: "YUNSHANGZHANTING",
          path: "cloudNew/list",
        },
        {
          name: "飞行影院",
          code: "FEIXINGYINGYUAN",
          path: "flyNew/list",
        },
      ],
      activeSub: "FEIXINGYINGYUAN",
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        publishFlag: 1,
        typeCode: "",
      }, // 新闻内容查询参数
      list: [], // 新闻列表
      total: 0, // 总新闻
    };
  },
  created() {
    this.getList();
  },
  mounted() {},
  methods: {
    // 二级字典切换
    changeSub(item) {
      if (this.activeSub != item.code) {
        this.$router.push("/imgNew/" + item.path);
      }
    },
    mSubChange(val) {
      const item = this.subArr.find((ele) => ele.code == val);
      this.$router.push("/imgNew/" + item.path);
    },
    // 获取新闻列表
    getList() {
      this.queryParams.typeCode = this.activeSub;
      this.$api.sysNewsList(this.queryParams).then((res) => {
        this.list = res.rows;
        this.total = res.total;
      });
    },
    currentChange(val) {
      this.queryParams.pageNum = val;
      this.getList(this.queryParams);
    },
    getDetail(item) {
      this.$router.push("/imgNew/flyNew/detail?id=" + item.id);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/style/color.scss";
.content {
  width: 1200px;
  min-height: 100px;
  margin: 20px auto 40px;
  box-shadow: 0 0 5px #ccc;
  border-radius: 10px;
  padding: 20px;
  box-sizing: border-box;
  background-color: #fff;

  .list {
    .item {
      width: 100%;
      padding: 10px;
      box-sizing: border-box;
      border-radius: 5px;
      border-bottom: 1px solid #eee;
      display: flex;
      cursor: pointer;

      &:hover {
        background-color: #efefef;
      }

      .post {
        width: 300px;
        height: 350px;
        border-radius: 5px;
        overflow: hidden;
        flex-shrink: 0;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .info {
        flex-grow: 1;
        margin-left: 30px;

        .title {
          max-width: 320px;
          font-weight: bold;
          font-size: 20px;
          margin-top: 20px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .subTitle {
          font-size: 14px;
          margin-top: 5px;
        }
        .in {
          margin: 15px 0;
          font-size: 14px;
          white-space: pre-wrap;
          line-height: 1.6;

          .desc {
            display: flex;
            font-size: 14px;
            margin-bottom: 7px;

            .label {
              flex-shrink: 0;
            }
            .val {
              display: -webkit-box;
              -webkit-line-clamp: 3;
              -webkit-box-orient: vertical;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }
        .more {
          width: fit-content;
          font-size: 14px;
          color: $theme;
          cursor: pointer;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }

  .rich {
    font-size: 14px;

    ::v-deep {
      img {
        width: 100%;
      }
    }
  }
}

.m_types {
  display: none;
  padding-top: 10px;
}

@media (max-width: 1366px) {
  .content {
    width: 1000px;

    .list .item .post {
      width: 250px;
      height: 320px;
    }
  }
}

@media (max-width: 960px) {
  .types {
    display: none;
  }

  .m_types {
    display: block;
  }

  .content {
    width: 100%;
    margin: 0;
    padding: 10px;
    box-shadow: none;

    .list .item {
      flex-direction: column;
      background-color: #fff;
      padding: 0;
      margin-bottom: 10px;

      .post {
        width: 100%;
        height: 210px;
      }
      .info {
        margin-top: 6px;
        margin-left: 0;
        padding: 5px;

        .title {
          margin-top: 0;
        }

        .in {
          margin: 10px 0;
        }
      }
    }
  }
}
</style>
