import request from "../request";
/**
 *  获取所有的权限
 * @method getAllPermission
 * @return {[type]}         [description]
 */

//获取所有友情链接
function getFriendList(params) {
  return request({
    url: `wx/web/sysFriendLink/list`,
    method: "get",
    params
  });
}

export default {
  getFriendList
};
