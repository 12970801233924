import banner from "./banner/banner";
import dictionary from "./dictionary/dictionary";
import friendlink from "./friendlink/friendlink";
import appointment from "@/util/appointment/appointment";
import news from "./news/news";

export default {
  ...banner,
  ...dictionary,
  ...friendlink,
  ...news,
  ...appointment,
};
