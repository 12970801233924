<!-- 新闻详情 -->
<template>
  <div class="detail">
    <div class="back" @click="$router.back()">
      <img src="@/assets/back.png" alt="返回" class="icon" />
      <span>返回</span>
    </div>
    <div class="title">{{ detail.title }}</div>
    <div class="time">{{ handleTime(detail.createTime) }}</div>
    <div class="rich" v-html="detail.content"></div>
  </div>
</template>

<script>
import { getWxDetails } from "@/util/content/content";
export default {
  data() {
    return {
      detail: {},
    };
  },
  computed: {
    handleTime() {
      // 时间格式处理
      return (time) => {
        return time && time.split(" ")[0];
      };
    },
  },
  created() {
    this.getDetail(this.$route.params.type);
  },
  mounted() {},
  methods: {
    getDetail(param) {
      getWxDetails({
        type:param
      }).then(res=>{
        if (res.code===200){
          this.detail=res.data;
        }
      })
    },
  },
};
</script>

<style lang='scss' scoped>
.detail {
  width: 1200px;
  margin: 0 auto;
  min-height: 500px;
  text-align: center;
  color: #333;
  position: relative;

  .back {
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    cursor: pointer;

    &:hover {
      color: $theme;

      .icon {
        transform: rotate(0);
      }
    }

    .icon {
      width: 30px;
      transform: scale(0.9) rotate(30deg);
      transition: all 0.5s;
    }
    span {
      padding-left: 10px;
    }
  }

  .title {
    font-size: 30px;
    margin-top: 40px;
  }
  .time {
    color: #919797;
    padding: 20px 0;
  }

  .rich {
    ::v-deep img {
      width: 100%;
    }
  }
}

@media (max-width: 1366px) {
  .detail {
    width: 1000px;
  }
}
@media (max-width: 960px) {
  .detail {
    width: 100%;

    .title {
      font-size: 25px;
    }
    .time {
      font-size: 14px;
    }
    .back {
      left: 20px;
      top: -20px;
    }
  }
}
</style>
