<!-- 新闻详情 -->
<template>
  <div class="classify baseMap">
    <div class="types">
      <div
        :class="{ type: true, active: activeSub == item.code }"
        v-for="item in subArr"
        :key="item.code"
        @click="changeSub(item)"
      >
        {{ item.name }}
      </div>
    </div>
    <van-tabs
      class="m_types"
      v-model="activeSub"
      color="#3ba86f"
      background="transparent"
      @change="mSubChange"
    >
      <van-tab
        :title="item.name"
        :name="item.code"
        v-for="item in subArr"
        :key="item.code"
      ></van-tab>
    </van-tabs>
    <div class="detail">
      <el-card>
        <div class="title">{{ detail.title }}</div>
        <div class="rich" v-html="detail.content"></div>
      </el-card>
    </div>
  </div>
</template>

<script>
import { getWxDetails } from "@/util/content/content";
export default {
  data() {
    return {
      detail: {
        title: "",
        content: "",
        createTime: "",
      },
      activeSub: "RUGUANXUZHI",
      subArr: [
        {
          name: "入馆须知",
          code: "RUGUANXUZHI",
          path: "/ushouldknow",
        },
        {
          name: "在线预约",
          code: "ZAIXIANYUYUE",
          path: "/reserve/index",
        },
        {
          name: "参观路线",
          code: "CANGUANLUXIAN",
          path: "/line/index",
        },
        {
          name: "交通信息",
          code: "JIAOTONGXINXI",
          path: "/traffic/index",
        },
        {
          name: "语音讲解",
          code: "YUYINJIANGJIE",
          path: "/voice/index",
        },
      ],
    };
  },
  computed: {
    handleTime() {
      // 时间格式处理
      return (time) => {
        return time && time.split(" ")[0];
      };
    },
  },
  created() {
    this.getDetail(this.activeSub);
  },
  mounted() {},
  methods: {
    getDetail(param) {
      this.detail = {
        title: "",
        content: "",
        createTime: "",
      };
      getWxDetails({
        type: param,
      }).then((res) => {
        if (res.code === 200) {
          if (res.data) {
            this.detail = res.data;
          }
        }
      });
    },
    changeSub(item) {
      if (this.activeSub != item.code) {
        this.$router.push("/service" + item.path);
      }
    },
    mSubChange(val) {
      const item = this.subArr.find((ele) => ele.code == val);
      this.$router.push("/service" + item.path);
    },
  },
};
</script>

<style lang="scss" scoped>
.detail {
  width: 1200px;
  margin: 40px auto;
  text-align: center;
  color: #333;
  position: relative;

  .back {
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    cursor: pointer;
    &:hover {
      color: $theme;
      .icon {
        transform: rotate(0);
      }
    }

    .icon {
      width: 30px;
      transform: scale(0.9) rotate(30deg);
      transition: all 0.5s;
    }
    span {
      padding-left: 10px;
    }
  }

  .title {
    font-size: 30px;
    margin-bottom: 20px;
  }
  .time {
    color: #919797;
    padding: 20px 0;
  }

  .rich {
    text-align: left;
  }
}

.title-box {
  text-align: center;
  font-size: 24px;
  padding: 40px 0 20px;
  font-weight: bold;
  color: #444;
}

.content {
  width: 1200px;
  min-height: 100px;
  margin: 40px auto;
  box-shadow: 0 0 5px #ccc;
  border-radius: 10px;
  padding: 20px;
  box-sizing: border-box;

  .list {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .item {
      width: 30%;
      margin-bottom: 20px;
      padding-bottom: 5px;
      border-radius: 5px;
      transition: 0.5s;

      &:not(:empty):hover {
        cursor: pointer;
        box-shadow: 0 0 5px #ccc;
        transform: translateY(-6px);
      }

      .post {
        width: 100%;
        height: 180px;
        border-radius: 5px;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .title {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding: 6px 10px;
      }

      .desc {
        padding: 2px 10px;
        font-size: 14px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    }
  }

  .rich {
    font-size: 14px;

    ::v-deep {
      img {
        width: 100%;
      }
    }
  }
}

.m_types {
  display: none;
}

@media (max-width: 1366px) {
  .content {
    width: 1000px;
  }
}

@media (max-width: 960px) {
  .types {
    display: none;
  }
  .m_types {
    display: block;
    padding-top: 10px;
  }
  .content,
  .detail {
    width: 100%;
    margin: 0;
    padding: 15px;
    box-shadow: none;
    box-sizing: border-box;

    .list .item {
      width: 100%;
    }
  }
}
</style>
