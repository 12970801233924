import request from "../request";
/**
 *  获取所有的权限
 * @method getAllPermission
 * @return {[type]}         [description]
 */

const baseUrl = "/wx/web/wxDetails";

//新闻详情
export  function getWxDetails(param) {
  return request({
    url: baseUrl,
    method: "get",
    params: param,
  });
}
