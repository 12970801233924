import Vue from "vue";
import VueRouter from "vue-router";

import index from "../views/index.vue";
import newsDetail from "../views/common/newsDetail.vue";
import wxDetail from "../views/common/contentDetail";
import home from "../views/home/Home";
// import guideList from "@/views/hallGuide/list";
import listTemplate from "@/views/listTemplate/index";
import reserve from "@/views/service/reserve/index";
import guideIntroduce from "@/views/hallGuide/guideIntroduce";
import floorIntroduce from "@/views/hallGuide/floorIntroduce";
import tempIntroduce from "@/views/hallGuide/tempIntroduce";
import tempIntroDetail from "@/views/hallGuide/tempIntroDetail";
import workNewsIndex from "@/views/webNews/workNews/index";
import planNewsIndex from "@/views/webNews/planNews/index";
import industryIndex from "@/views/webNews/industry/index";
import cloudNewsIndex from "@/views/imgNew/cloudNew/list";
import flyNewsIndex from "@/views/imgNew/flyNew/list";
import serviceLine from "@/views/service/line/index";
import trafficIndex from "@/views/service/traffic/index";
import voiceIndex from "@/views/service/voice/index";
import concatUs from "@/views/aboutUs/concatUs/index";
import employee from "@/views/aboutUs/employee/index";
import activityIndex from "@/views/webNews/activityNews/index";
import ushouldknow from "@/views/hallGuide/ushouldknow";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "index",
    component: index,
    children: [
      {
        path: "/",
        name: "首页",
        component: home,
      },
      {
        path: "/newsDetail/:id",
        name: "新闻详情",
        component: newsDetail,
      },
      {
        path: "/wxDetail/:type",
        name: "内容详情",
        component: wxDetail,
      },
      {
        path: "/guideList/guideIntroduce",
        name: "展馆概况",
        component: guideIntroduce,
      },
      {
        path: "/guideList/floorIntroduce",
        name: "楼层导览",
        component: floorIntroduce,
      },
      {
        path: "/guideList/tempIntroduce",
        name: "临时导览",
        component: tempIntroduce,
      },
      {
        path: "/guideList/tempIntroduce/:id",
        name: "临时导览详情",
        component: tempIntroDetail,
      },
      {
        path: "/webNews/workNews/index",
        name: "工作信息",
        component: workNewsIndex,
        meta: {
          keepAlive: true,
        },
      },
      {
        path: "/imgNew/cloudNew/list",
        name: "云上展厅",
        component: cloudNewsIndex,
      },
      {
        path: "/imgNew/flyNew/list",
        name: "飞行影院",
        component: flyNewsIndex,
      },
      {
        path: "/imgNew/flyNew/detail",
        name: "飞行影院详情",
        component: () => import("../views/imgNew/flyNew/detail.vue"),
      },
      {
        path: "/news/industry/index",
        name: "行业新闻",
        component: industryIndex,
        meta: {
          keepAlive: true,
        },
      },
      {
        path: "/news/planNews/index",
        name: "媒体报道",
        component: planNewsIndex,
        meta: {
          keepAlive: true,
        },
      },
      {
        path: "/service/line/index",
        name: "参观路线",
        component: serviceLine,
      },
      {
        path: "/service/traffic/index",
        name: "交通信息",
        component: trafficIndex,
      },
      {
        path: "/service/ushouldknow",
        name: "入馆须知",
        component: ushouldknow,
      },
      {
        path: "/service/voice/index",
        name: "语音讲解",
        component: voiceIndex,
      },
      {
        path: "/service/reserve/index",
        name: "预约",
        component: reserve,
      },
      {
        path: "/aboutUs/concatUs/index",
        name: "联系方式",
        component: concatUs,
      },
      {
        path: "/aboutUs/employee/index",
        name: "员工风采",
        component: employee,
      },
      {
        path: "/webNews/activityNews/index",
        name: "活动预告",
        component: activityIndex,
        meta: {
          keepAlive: true,
        },
      },
      {
        path: "/listTemplate",
        name: "列表页",
        component: listTemplate,
      },
      {
        path: "/search",
        name: "列表页",
        component: () => import("../views/search/search"),
      },
    ],
  },
];

const router = new VueRouter({
  // mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  // window.scrollTo(0, 0);
  next();
});

export default router;
