<!--  -->
<template>
  <div class="frame baseMap">
    <div class="detail">
      <div class="title">
        <div class="one">{{ detail.title }}</div>
        <div class="two">{{ detail.subTitle }}</div>
      </div>
      <!-- <pre class="time">{{ detail.introduce }}</pre> -->
      <div class="rich" v-html="detail.content"></div>
    </div>
  </div>
</template>

<script>
import { searchNewsinfo as detailNews } from "@/util/news/news";
export default {
  data() {
    return {
      detail: {},
    };
  },
  computed: {
    handleTime() {
      // 时间格式处理
      return (time) => {
        return time && time.split(" ")[0];
      };
    },
  },
  created() {
    this.getDetail(this.$route.params.id);
  },
  mounted() {},
  methods: {
    getDetail(id) {
      detailNews(id).then((res) => {
        if (res.code === 200) {
          let introArr = res.data.introduce.split("\n");
          res.data.introduce = introArr.slice(0, 2).join("\t");
          this.detail = res.data;
        }
      });
    },
  },
};
</script>

<style lang='scss' scoped>
.detail {
  width: 1200px;
  margin: 40px auto;
  min-height: 500px;
  text-align: center;
  color: #333;
  border: 1px solid #dedede;
  background-color: #fff;
  position: relative;
  .back {
    position: absolute;
    left: 20px;
    top: 5px;
    display: flex;
    align-items: center;
    cursor: pointer;
    &:hover {
      color: $theme;
      .icon {
        transform: rotate(0);
      }
    }

    .icon {
      width: 30px;
      transform: scale(0.9) rotate(30deg);
      transition: all 0.5s;
    }
    span {
      padding-left: 10px;
    }
  }

  .title {
    font-size: 30px;
    padding: 35px 10px 10px;
    border-bottom: 1px solid #dedede;

    .two {
      font-size: 18px;
      margin-top: 5px;
    }
  }
  .time {
    color: #919797;
    padding-bottom: 20px;
    margin: 0 20px;
    border-bottom: 1px solid #dedede;
    white-space: pre-wrap;
  }

  .rich {
    text-align: left;
    margin-bottom: 30px;
    padding: 20px;

    ::v-deep img {
      width: 70%;
      height: auto;
      display: block;
      margin: 5px auto;

      & + img {
        margin-top: 10px;
      }
    }
  }
}

@media (max-width: 1366px) {
  .detail {
    width: 1000px;
  }
}

@media (max-width: 960px) {
  .detail {
    width: 100%;
    padding: 0 15px;
    box-sizing: border-box;
    box-shadow: none;

    .title {
      font-size: 20px;
    }
    .time {
      font-size: 14px;
    }
    .back {
      left: 20px;
      top: -20px;
    }
    .rich {
      width: 100%;
      box-sizing: border-box;
    }
  }
}
</style>
