export default {
  //tool.js
  //日期截取
  timeSub(data) {
    let time = data.substring(0, 10)
    return time
  },
  indexstyle: {
    height: document.documentElement.clientHeight + 'px',
  },
  btnRoles: JSON.parse(localStorage.getItem('perms')),
  _isMobile() {
    let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
    return flag;
  }
}
