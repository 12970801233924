import request from "../request";
/**
 *  获取所有的权限
 * @method getAllPermission
 * @return {[type]}         [description]
 */

//根据一级查询2，3级code
function listDictByType(form) {
  return request({
    url: `/wx/dict/getRoomList`,
    method: "get",
    params: form,
  });
}
function listAppointType(){
  return request({
    url: `/wx/dict/getAppointmentType`,
    method: "get",

  });
}

export default {
  listDictByType,
  listAppointType
};
