<template>
  <footer class="footer">
    <div class="link-box">
      <div class="links">
        <div class="title">友情链接：</div>
        <div class="in">
          <a
            :href="item.hrefUrl"
            rel="nofollow"
            target="_blank"
            class="link"
            v-for="item in friendLinkList"
            :key="item.id"
            >{{ item.name }}</a
          >
        </div>
      </div>
    </div>
    <div class="bottom">
      <div class="bottom-top">
        <div class="codes">
          <div class="code">
            <img src="../assets/weixin_code.jpg" alt="wexin" class="icon" />
            <div class="label">公众号二维码</div>
          </div>
          <div class="code">
            <img src="../assets/service_code.jpg" alt="service" class="icon" />
            <div class="label">客服二维码</div>
          </div>
        </div>
        <img src="../assets/vertical.png" class="vertical" />
        <div class="contact-box">
          <div class="contacts">
            <div class="contact" @click="makePhone('0816-8797666')">
              <div class="title">游客服务</div>
            </div>
            <div class="contact" @click="makePhone('0816-8797666')">
              <div class="title">对外合作</div>
            </div>
            <div class="contact" @click="makePhone('0816-8797666')">
              <div class="title">招商咨询</div>
            </div>
            <div class="contact" @click="makePhone('0816-8797666')">
              <div class="title">商务咨询</div>
            </div>
          </div>
          <div class="number">
            <img src="@/assets/phone_icon.png" alt="" />
            <span>联系电话：0816-8797666</span>
          </div>
        </div>
      </div>
      <div class="bottom-under">
        <!-- <div class="footer-navs">
          <div class="nav">关于我们</div>
          <div class="nav">版权保护</div>
          <div class="nav">隐私声明</div>
        </div> -->
        <p class="gongan">
          <img src="@/assets/gongan.jpg" style="float: left" />
          <span>川公网安备 51078202110231号</span>
        </p>
        <p>
          <a href="https://beian.miit.gov.cn/" target="_blank" rel="nofollow"
            >蜀ICP备19019182号-2</a
          >
        </p>
        <p>技术支持电话：0816-8797666</p>
      </div>
    </div>
  </footer>
</template>

<script>
// import { sysFriendLinkList } from "@/util/friendlink/friendlink";
export default {
  data() {
    return {
      isMobile: sessionStorage.getItem("isMobile"),
      setInfo: {},
      friendLinkList: [],
    };
  },
  created() {
    this.getFriendLink();
    //配置信息
    // this.$api.searchwebConfig().then((res) => {
    //   if (res.code === 0) {
    //     this.setInfo = res.data;
    //   }
    // });
  },
  methods: {
    // 友情链接数据
    async getFriendLink() {
      const params = {
        pageNum: 1,
        pageSize: 20,
      };
      let res = await this.$api.getFriendList(params);
      this.friendLinkList = res.rows;
    },
    makePhone(number) {
      window.location.href = "tel:" + number;
    },
  },
};
</script>
<style lang="scss" scoped>
.footer {
  width: 100%;

  .link-box {
    width: 100%;
    height: 50px;
    line-height: 50px;
    background-color: $theme;

    .links {
      width: 1200px;
      height: 100%;
      margin: 0 auto;
      color: #fff;
      font-size: 18px;
      display: flex;

      .title {
        flex-shrink: 0;
      }
      .in {
        flex-grow: 1;
        overflow: auto;
        overflow-y: hidden;
        display: flex;

        &:hover {
          &::-webkit-scrollbar {
            height: 7px;
          }
        }

        &::-webkit-scrollbar-thumb {
          background-color: #346542;
          border-radius: 10px;
          -webkit-box-shadow: inset 1px 1px 0 rgba(0, 0, 0, 0.1);
        }

        .link {
          color: #fff;
          margin-right: 30px;
          flex-shrink: 0;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }

  .bottom {
    width: 1200px;
    margin: 0 auto;
    padding-top: 30px;

    &-top {
      display: flex;
      justify-content: center;
      margin-bottom: 10px;

      .codes {
        display: flex;

        .code {
          text-align: center;

          .icon {
            width: auto;
            height: 120px;
          }
        }
      }
      .vertical {
        width: auto;
        height: 140px;
        margin: 0 70px 0 90px;
      }
      .contact-box {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
      }
      .contacts {
        width: 450px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        .contact {
          width: 100px;
          height: 40px;
          background-color: $theme;
          border-radius: 5px;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          color: #fff;
          font-size: 15px;

          &:hover {
            background-color: #329260;
          }

          .phone {
            margin-top: 5px;
            font-size: 14px;
          }
        }
      }
      .number {
        font-size: 18px;
        font-weight: bold;
        color: $theme;

        img {
          width: 25px;
          vertical-align: middle;
          margin-right: 10px;
        }
      }
    }
    &-under {
      width: 1200px;
      height: 40px;
      line-height: 40px;
      margin: 0 auto;
      font-size: 14px;
      color: #666;
      display: flex;
      justify-content: center;

      .footer-navs {
        color: red;
        display: flex;

        .nav {
          position: relative;
          cursor: pointer;

          &:hover {
            text-decoration: underline;
          }

          & + .nav {
            margin-left: 20px;

            &::before {
              content: "";
              display: block;
              width: 10px;
              height: 1px;
              background-color: #333;
              position: absolute;
              top: 50%;
              left: -25%;
              transform: translateY(-50%);
            }
          }
        }
      }
      p {
        & + p {
          margin-left: 10px;
        }
        a {
          color: #333;
        }
      }
      .gongan {
        display: flex;
        align-items: center;

        img {
          margin-right: 5px;
        }
      }
    }
  }
}

@media (max-width: 1366px) {
  .footer {
    .link-box .links {
      width: 1000px;
      font-size: 16px;
    }
    .bottom {
      width: 1000px;
    }
    .bottom-top .vertical {
      margin: 0 50px;
    }
  }
}
@media (max-width: 960px) {
  .footer {
    // height: fit-content;
    background-color: #3f3f3f;

    .link-box {
      display: none;
    }

    .bottom {
      width: 100%;

      .bottom-top {
        .codes,
        .vertical {
          display: none;
        }
        .contacts {
          width: 100%;
          padding: 0 0.2rem;

          .contact {
            width: 47%;
            height: 40px;
            margin-bottom: 10px;
            font-size: 14px;

            .phone {
              font-size: 13px;
            }
          }
        }
      }
      .bottom-under {
        width: 100%;
        height: auto;
        padding-bottom: 10px;
        line-height: 1.5;
        font-size: 13px;
        text-align: center;
        color: #fff;
        display: block;

        .footer-navs {
          display: none;
        }
        p a {
          color: #fff;
        }
        .gongan {
          justify-content: center;
        }
      }
    }

    .footer-friendlink {
      line-height: 30px;
      width: 90%;
      padding: 0 5% 20px 5%;
      span {
        margin-right: 30px;
      }
      a {
        margin-right: 20px;
        padding: 5px;
        background-color: #73a2e9;
        color: #fff !important;
        font-size: 12px !important;
      }
    }
    .st-record {
      width: 90%;
      padding: 10px 5% 60px 5%;
      background-color: #f5f5f5;
      text-align: center;
      line-height: 20px;
      font-size: 12px;
      color: #b0b0b0;
    }
  }
}
</style>
