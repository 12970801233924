import request from "../request";
/**
 *  获取所有的权限
 * @method getAllPermission
 * @return {[type]}         [description]
 */
const baseUrl = "/wx/appointment";

//查询已发布的banner列表
export function appointmentList(form) {
  return request({
    url: baseUrl + `/listAppointment`,
    method: "get",
    params: form,
  });
}
export function sendCode(params) {
  return request({
    url: "/wx/wxUser/sendCode",
    method: "post",
    params: params,
  });
}

export function submitForm(data) {
  return request({
    url: "/wx/web/submitAppointment",
    method: "post",
    data: data,
  });
}
export default {
  appointmentList,
  sendCode,
    submitForm
};
