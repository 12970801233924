<!--  -->
<template>
  <div class="classify">
    <div class="title-box">
      <div class="title">{{ parentDic.name }}</div>
    </div>
    <div class="types">
      <div
        :class="{ type: true, active: activeSub == item.code }"
        v-for="item in parentDic.children"
        :key="item.id"
        @click="changeSub(item)"
      >
        {{ item.name }}
      </div>
    </div>
    <van-tabs
      class="m_types"
      v-model="activeSub"
      color="#3ba86f"
      background="transparent"
    >
      <van-tab
        :title="item.name"
        :name="item.code"
        v-for="item in parentDic.children"
        :key="item.id"
        @click="changeSub(item)"
      ></van-tab>
    </van-tabs>
    <div class="content">
      <div class="list-item">
        <div class="item" v-for="(item, index) in 10" :key="index">
          <div class="left">恭喜诺拜尔成为阿词玛全国总代理</div>
          <div class="right">2023-02-20 00:00:00</div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
export default {
  data() {
    return {
      parentDic: {
        children: [
          { id: 1, name: "菜单一" },
          { id: 2, name: "菜单二" },
        ],
      },
      nowDic: {},
      activeSub: "",
      queryParams: {
        pageIndex: 1,
        pageSize: 9,
        code: "",
      }, // 新闻内容查询参数
      list: [], // 新闻列表
      total: 0, // 总新闻
    };
  },
  created() {},
  mounted() {},
  methods: {
    // 二级字典切换
    changeSub(item) {
      this.activeSub = item.code;
      this.queryParams.code = item.code;
      let params = { ...this.queryParams };
      // 调用获取新闻内容的接口
      if (item.clickConfig == 1) {
        // 富文本只获取一个
        params.pageSize = 1;
      }
      this.getList(params);
    },
    // 获取新闻列表
    getList(params) {
      console.log(params);
    },
    currentChange(val) {
      this.queryParams.pageIndex = val;
      this.getList(this.queryParams);
    },
    getDetail(item) {
      console.log(item);
      this.$router.push("/detail/" + 123);
    },
  },
};
</script>
  
<style lang='scss' scoped>
@import "../../style/color.scss";

.title-box {
  text-align: center;
  font-size: 24px;
  padding: 40px 0 20px;
  font-weight: bold;
  color: #444;
}

.types {
  border-bottom: 1px solid #ccc;
  display: flex;
  justify-content: center;

  .type {
    padding: 5px 10px;
    cursor: pointer;
    position: relative;

    &:hover {
      color: $theme;

      &::after {
        display: block;
      }
    }

    & + .type {
      margin-left: 10px;
    }

    &::after {
      content: "";
      display: none;
      width: 50%;
      height: 2px;
      border-radius: 6px;
      background-color: $theme;
      position: absolute;
      left: 50%;
      bottom: 0;
      transform: translateX(-50%);
    }
  }

  .active {
    color: $theme;

    &::after {
      display: block;
    }
  }
}

.content {
  width: 1200px;
  min-height: 100px;
  margin: 40px auto;
  box-shadow: 0 0 5px #ccc;
  border-radius: 10px;
  padding: 20px;
  box-sizing: border-box;

  .list-item {
    .item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 0;
      cursor: pointer;

      .left {
        max-width: 70%;
      }

      .right {
      }
    }
  }
}

.m_types {
  display: none;
}

@media (max-width: 1366px) {
  .content {
    width: 1000px;
  }
}

@media (max-width: 960px) {
  .types {
    display: none;
  }

  .m_types {
    display: block;
  }

  .content {
    width: 100%;
    margin: 0;
    padding: 15px;
    box-shadow: none;
  }
}
</style>
  