<!--  -->
<template>
  <div class="classify baseMap">
    <div class="types">
      <div
        :class="{ type: true, active: activeSub == item.code }"
        v-for="item in subArr"
        :key="item.code"
        @click="changeSub(item)"
      >
        {{ item.name }}
      </div>
    </div>
    <van-tabs
      class="m_types"
      v-model="activeSub"
      color="#3ba86f"
      background="transparent"
      @change="mSubChange"
    >
      <van-tab
        :title="item.name"
        :name="item.code"
        v-for="item in subArr"
        :key="item.code"
      ></van-tab>
    </van-tabs>
    <div class="content">
      <div class="box-list">
        <el-card v-for="item in list" :key="item.id">
          <div class="box-info" @click="getDetail(item)">
            <div class="left">
              <div class="top">
                <div class="time">
                  {{ $dayjs(item.publishTime || item.createTime).format("YYYY")
                  }}<br /><span>{{
                    $dayjs(item.publishTime || item.createTime).format("MM/DD")
                  }}</span>
                </div>
                <div class="title">{{ item.title }}</div>
              </div>
              <div class="desc">{{ item.introduce }}</div>
            </div>
            <div class="right" v-if="item.imgUrl">
              <img :src="URL + item.imgUrl" :alt="item.title" />
            </div>
          </div>
        </el-card>
      </div>
      <el-pagination
        :hide-on-single-page="total <= queryParams.pageSize"
        @current-change="currentChange"
        :current-page.sync="queryParams.pageNum"
        :page-size="queryParams.pageSize"
        layout="total, prev, pager, next"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
import { URL } from "@/util/config";
export default {
  data() {
    return {
      URL: URL,
      subArr: [
        {
          name: "行业新闻",
          code: "HANGYEXINWEN",
        },
        {
          name: "媒体报道",
          code: "MEITIBAODAO",
        },
      ],
      activeSub: "HANGYEXINWEN",
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        publishFlag: 1,
        typeCode: "",
      }, // 新闻内容查询参数
      list: [], // 新闻列表
      total: 0, // 总新闻
    };
  },
  created() {},
  mounted() {
    this.getList();
  },
  methods: {
    // 二级字典切换
    changeSub(item) {
      this.activeSub = item.code;
      this.queryParams.pageNum = 1;
      this.getList();
    },
    mSubChange(val) {
      this.activeSub = val;
      this.queryParams.pageNum = 1;
      this.getList();
    },
    // 获取新闻列表
    getList() {
      this.queryParams.typeCode = this.activeSub;
      this.$api.sysNewsList(this.queryParams).then((res) => {
        this.list = res.rows;
        this.total = res.total;
      });
    },
    currentChange(val) {
      this.queryParams.pageNum = val;
      this.getList(this.queryParams);
    },
    getDetail(item) {
      if (item.urlEnable === 1) {
        window.open(item.linkUrl);
      } else {
        this.$router.push("/newsDetail/" + item.id);
      }
    },
  },
  beforeRouteLeave(to, from, next) {
    from.meta.keepAlive = to.path.indexOf("/newsDetail/") != -1;
    next();
  },
};
</script>

<style lang="scss" scoped>
@import "@/style/color.scss";

.title-box {
  text-align: center;
  font-size: 24px;
  padding: 40px 0 20px;
  font-weight: bold;
  color: #444;
}
.content {
  width: 1200px;
  min-height: 100px;
  margin: 0 auto 40px;
  border-radius: 10px;
  padding: 20px;
  box-sizing: border-box;

  .el-card {
    margin-bottom: 30px;
    transition: transform 0.5s;

    &:hover {
      transform: translateY(-10px);
    }
  }

  .box-info {
    display: flex;
    cursor: pointer;

    &:hover {
      .left {
        .top {
          .title {
            color: $theme;
          }
        }
      }
      .right img {
        transform: scale(1.1);
      }
    }

    .left {
      flex-grow: 1;
      margin-right: 10px;

      .top {
        padding-bottom: 10px;
        display: flex;
        align-items: center;
        border-bottom: 1px solid #eee;

        .time {
          font-size: 14px;
          color: #999;
          text-align: center;
          padding-right: 10px;
          margin-right: 10px;
          border-right: 1px solid #eee;

          span {
            font-size: 18px;
            color: #333;
          }
        }
        .title {
          max-width: 500px;
          font-size: 20px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
      .desc {
        text-indent: 2em;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        margin: 10px 0;
        white-space: pre-wrap;
      }
    }
    .right {
      width: 400px;
      height: 200px;
      flex-shrink: 0;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: transform 0.5s;
      }
    }
  }
}
.m_types {
  display: none;
  padding-top: 10px;
}
@media (max-width: 1366px) {
  .content {
    width: 1000px;

    .box-info .left .top .title {
      max-width: 300px;
    }
  }
}

@media (max-width: 960px) {
  .types {
    display: none;
  }

  .m_types {
    display: block;
  }

  .content {
    width: 100%;
    margin: 0;
    padding: 15px;
    box-shadow: none;

    .box-info {
      display: block;

      .left {
        .top {
          .time {
            span {
              font-size: 16px;
            }
          }
          .title {
            font-size: 16px;
          }
        }
        .desc {
          display: none;
        }
      }
      .right {
        width: 100%;
        height: 170px;
      }
    }
  }
}
</style>
