<!-- 新闻详情 -->
<template>
  <div class="frame baseMap">
    <div class="detail">
      <div class="back" @click="$router.back()">
        <img src="@/assets/back.png" alt="返回" class="icon" />
        <span>返回</span>
      </div>
      <div class="title">{{ detail.title }}</div>
      <div class="subTitle" v-show="detail.subTitle">{{ detail.subTitle }}</div>
      <div class="time">
        {{ handleTime(detail.publishTime || detail.createTime) }}
      </div>
      <div class="rich" v-html="detail.content"></div>
    </div>
  </div>
</template>

<script>
import { searchNewsinfo as detailNews } from "@/util/news/news";
export default {
  data() {
    return {
      detail: {},
    };
  },
  computed: {
    handleTime() {
      // 时间格式处理
      return (time) => {
        return time && time.split(" ")[0];
      };
    },
  },
  created() {
    this.getDetail(this.$route.params.id);
  },
  mounted() {},
  methods: {
    getDetail(id) {
      detailNews(id).then((res) => {
        if (res.code === 200) {
          this.detail = res.data;
        }
      });
    },
  },
};
</script>

<style lang='scss' scoped>
.detail {
  width: 1200px;
  margin: 40px auto;
  min-height: 500px;
  text-align: center;
  color: #333;
  border: 1px solid #dedede;
  background-color: #fff;
  position: relative;

  .back {
    position: absolute;
    left: 20px;
    top: 5px;
    display: flex;
    align-items: center;
    cursor: pointer;

    &:hover {
      color: $theme;

      .icon {
        transform: rotate(0);
      }
    }

    .icon {
      width: 30px;
      transform: scale(0.9) rotate(30deg);
      transition: all 0.5s;
    }
    span {
      padding-left: 10px;
    }
  }

  .title {
    font-size: 30px;
    padding: 55px 10px 10px;
  }
  .subTitle {
    font-size: 15px;
    margin-bottom: 10px;
  }
  .time {
    font-size: 14px;
    color: #919797;
    padding-bottom: 20px;
    margin: 0 20px;
    border-bottom: 1px solid #dedede;
  }

  .rich {
    text-align: left;
    margin-bottom: 30px;
    padding: 20px;

    ::v-deep img {
      width: 70%;
      height: auto;
      display: block;
      margin: 5px auto;

      & + img {
        margin-top: 10px;
      }
    }
  }
}

@media (max-width: 1366px) {
  .detail {
    width: 1000px;
  }
}
@media (max-width: 960px) {
  .detail {
    width: 100%;
    padding: 0 15px;
    box-sizing: border-box;
    border: none;

    .title {
      font-size: 25px;
    }
    .time {
      font-size: 14px;
    }
    .back {
      left: 20px;
      top: -20px;
    }
    .rich {
      width: 100%;
      box-sizing: border-box;
    }
  }
}
</style>
