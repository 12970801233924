<!-- 新闻详情 -->
<template>
  <div class="classify baseMap">
    <div class="types">
      <div
        :class="{ type: true, active: activeSub == item.code }"
        v-for="item in subArr"
        :key="item.code"
        @click="changeSub(item)"
      >
        {{ item.name }}
      </div>
    </div>
    <van-tabs
      class="m_types"
      v-model="activeSub"
      color="#3ba86f"
      background="transparent"
      @change="mSubChange"
    >
      <van-tab
        :title="item.name"
        :name="item.code"
        v-for="item in subArr"
        :key="item.code"
      ></van-tab>
    </van-tabs>
    <div class="content">
      <el-card>
        <div class="title">地图导航</div>
        <div class="box">
          <div id="container"></div>
          <div class="info">
            <div class="way">
              <div class="label">
                <img src="@/assets/train.png" class="logo" />
                <span>火车站</span>前往
              </div>
              <div class="val">
                <p>直达线路：乘公交车85路到科创园站</p>
                <p>中转线路：乘71/39/806/302/15/2路换乘85路到科创园站</p>
                <p>乘39/38/35路换乘13路或13路农校专线科创园站</p>
              </div>
            </div>
            <div class="way">
              <div class="label">
                <img src="@/assets/plane.jpg" class="logo" />
                <span style="color: #50a6ee">飞机场</span>前往
              </div>
              <div class="val">
                <p>公交线路：乘坐304路到换乘85路到科创园站</p>
                <p>乘坐58路换乘13路或13路农校专线到科创园站</p>
                <p>乘坐观光601路换乘85路到科创园站</p>
              </div>
            </div>
            <div class="way">
              <div class="label">
                <img src="@/assets/bus.png" class="logo" />
                <span style="color: #de5241">公交车</span>前往
              </div>
              <div class="val">
                <p>公交站：科创园站</p>
                <p>直达线路：13路、13路农校专线、85路</p>
                <p>其他出行方式推荐自驾、出租车等</p>
              </div>
            </div>
          </div>
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
import { getWxDetails } from "@/util/content/content";
export default {
  data() {
    return {
      detail: {
        title: "",
        content: "",
        createTime: "",
      },
      activeSub: "JIAOTONGXINXI",
      subArr: [
        {
          name: "入馆须知",
          code: "RUGUANXUZHI",
          path: "/ushouldknow",
        },
        {
          name: "在线预约",
          code: "ZAIXIANYUYUE",
          path: "/reserve/index",
        },
        {
          name: "参观路线",
          code: "CANGUANLUXIAN",
          path: "/line/index",
        },
        {
          name: "交通信息",
          code: "JIAOTONGXINXI",
          path: "/traffic/index",
        },
        {
          name: "语音讲解",
          code: "YUYINJIANGJIE",
          path: "/voice/index",
        },
      ],
      map: null,
    };
  },
  computed: {
    handleTime() {
      // 时间格式处理
      return (time) => {
        return time && time.split(" ")[0];
      };
    },
  },
  created() {},
  mounted() {
    this.initMap();
  },
  methods: {
    getDetail(param) {
      this.detail = {
        title: "",
        content: "",
        createTime: "",
      };
      getWxDetails({
        type: param,
      }).then((res) => {
        if (res.code === 200) {
          if (res.data) {
            this.detail = res.data;
          }
        }
      });
    },
    changeSub(item) {
      if (this.activeSub != item.code) {
        this.$router.push("/service" + item.path);
      }
      // 调用获取新闻内容的接口
      // this.getDetail(item.code);
    },
    mSubChange(val) {
      const item = this.subArr.find((ele) => ele.code == val);
      this.$router.push("/service" + item.path);
    },
    initMap() {
      this.map = new window.AMap.Map("container", {
        zoom: 17, //级别
        center: [104.703034, 31.49376],
      });
      const icon = new window.AMap.Icon({
        image: require("../../../assets/location.png"),
        size: new window.AMap.Size(30, 32),
        imageSize: new window.AMap.Size(30, 32),
      });
      let marker = new window.AMap.Marker({
        position: [104.703034, 31.49376], //位置
        icon,
        offset: new window.AMap.Pixel(-15, -32),
      });
      this.map.add(marker);
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  width: 1200px;
  margin: 20px auto 40px;

  .title {
    font-size: 30px;
    color: $theme;
    margin-bottom: 40px;
  }
  .box {
    margin-bottom: 20px;
    border-bottom: 1px solid #eee;

    #container {
      width: 100%;
      height: 600px;
    }
    .info {
      margin: 30px 0;
      display: flex;

      .way {
        flex: 1;
        margin-top: 10px;
        margin-bottom: 5px;
        & + .way {
          margin-left: 20px;
        }

        .label {
          font-size: 20px;
          font-weight: bold;
          border-bottom: 1px solid #eee;
          padding-bottom: 10px;
          margin-bottom: 10px;
          display: flex;
          align-items: center;

          .logo {
            width: auto;
            height: 40px;
            margin-right: 5px;
          }

          span {
            color: $theme;
          }
        }
        .val {
          font-size: 14px;
          line-height: 1.6;
        }
      }
    }
  }
}

.m_types {
  display: none;
}

@media (max-width: 1366px) {
  .content {
    width: 1000px;
  }
}

@media (max-width: 750px) {
  .types {
    display: none;
  }
  .m_types {
    display: block;
    padding-top: 10px;
  }
  .content {
    width: 100%;
    margin: 20px 0;
    padding: 10px;
    box-sizing: border-box;

    .title {
      font-size: 20px;
    }

    .box {
      #container {
        height: 60vh;
      }
      .info {
        flex-direction: column;

        .way {
          & + .way {
            margin-left: 0;
          }
        }
      }
    }
  }
}
</style>
