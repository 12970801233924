<!-- 自动向左滚动组件 -->
<template>
  <div class="announcement">
    <div class="cont">
      <div
        class="contlist"
        ref="contlist"
        @mouseover="stopScroll"
        @mouseout="startScroll"
      >
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      scrollW: 0, //记录滚动到哪了
      ableScroll: true, // 是否可以滚动
    };
  },
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    list() {
      clearInterval(this.scrollTime);
      this.scrollW = 0;
      this.$refs.contlist.style.left = 0;
      setTimeout(() => {
        this.ableRoll();
      }, 10);
    },
  },
  created() {},
  mounted() {},
  methods: {
    // 判断是否超出父辈长度，若超出则开启滚动
    ableRoll() {
      let outDom = document.getElementsByClassName("announcement")[0];
      let target = this.$refs.contlist;
      this.ableScroll = target.offsetWidth > outDom.offsetWidth;
      if (target.offsetWidth > outDom.offsetWidth) {
        this.scroll();
      }
    },
    //鼠标悬停，停止滚动
    stopScroll() {
      clearInterval(this.scrollTime);
    },
    //鼠标移开 ，接着滚动
    startScroll() {
      if (!this.ableScroll) return;
      var target = this.$refs.contlist;
      this.scrollW = target.offsetLeft; // 移开时记录向左移动的距离
      this.ableRoll();
    },
    //循环滚动
    scroll() {
      var target = this.$refs.contlist;
      var initLeft = 0;
      if (this.scrollW < 0) {
        initLeft = this.scrollW * -1;
      }
      // 定时器
      this.scrollTime = setInterval(function () {
        initLeft++;
        if (initLeft >= target.offsetWidth / 2) {
          initLeft = 0;
        }
        target.style.left = "-" + initLeft + "px";
      }, 10);
    },
  },
};
</script>


<style scoped lang="scss">
ul,
li,
em {
  margin: 0;
  padding: 0;
}
.cont {
  height: 150px;
  color: #fff;
  overflow: hidden;
  position: relative;
}
.contlist {
  height: 100%;
  position: absolute;
  white-space: nowrap;
  display: flex;
  flex-direction: row;
}
</style>
