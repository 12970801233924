<!-- 新闻详情 -->
<template>
  <div class="classify baseMap">
    <div class="types">
      <div
        :class="{ type: true, active: activeSub == item.code }"
        v-for="item in subArr"
        :key="item.code"
        @click="changeSub(item)"
      >
        {{ item.name }}
      </div>
    </div>
    <van-tabs
      class="m_types"
      v-model="activeSub"
      color="#3ba86f"
      background="transparent"
      @change="mSubChange"
    >
      <van-tab
        :title="item.name"
        :name="item.code"
        v-for="item in subArr"
        :key="item.code"
      ></van-tab>
    </van-tabs>
    <div class="content">
      <el-card>
        <div class="in">
          <div class="left">
            <div class="title">绵阳城市规划展览馆</div>
            <div class="desc">
              <div class="p">
                <img src="../../../assets/address.png" class="icon" />
                <p>地址：四川省绵阳市涪城区九洲大道166号</p>
              </div>
              <div class="p">
                <img src="../../../assets/email.png" class="icon" />
                <p>邮编：621000</p>
              </div>
              <div class="p">
                <img src="../../../assets/fax.png" class="icon" />
                <p>传真：0816-8797666</p>
              </div>
              <div class="p">
                <img src="../../../assets/fax.png" class="icon" />
                <p>座机：0816-8797666</p>
              </div>
              <div class="p">
                <img src="../../../assets/phone.png" class="icon" />
                <p>电话：15308335620</p>
              </div>
            </div>
          </div>
          <div class="right">
            <div class="box">
              <div id="container"></div>
            </div>
          </div>
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
import { getWxDetails } from "@/util/content/content";
export default {
  data() {
    return {
      detail: {
        title: "",
        content: "",
        createTime: "",
      },
      activeSub: "LIANXIFANGSHI",
      subArr: [
        {
          name: "联系方式",
          code: "LIANXIFANGSHI",
        },
        {
          name: "员工风采",
          code: "YUANGONGFENGCAI",
        },
      ],
      map: null,
    };
  },
  computed: {
    handleTime() {
      // 时间格式处理
      return (time) => {
        return time && time.split(" ")[0];
      };
    },
  },
  created() {},
  mounted() {
    this.initMap();
  },
  methods: {
    getDetail(param) {
      this.detail = {
        title: "",
        content: "",
        createTime: "",
      };
      getWxDetails({
        type: param,
      }).then((res) => {
        if (res.code === 200) {
          if (res.data) {
            this.detail = res.data;
          }
        }
      });
    },
    changeSub(item) {
      this.activeSub = item.code;
      if (item.code == "YUANGONGFENGCAI") {
        this.$router.push({
          path: "/aboutUs/employee/index",
        });
      }
      // 调用获取新闻内容的接口
      this.getDetail(item.code);
    },
    mSubChange(code) {
      if (code == "YUANGONGFENGCAI") {
        this.$router.push("/aboutUs/employee/index");
      }
    },
    initMap() {
      this.map = new window.AMap.Map("container", {
        zoom: 17, //级别
        center: [104.703034, 31.49376],
      });
      const icon = new window.AMap.Icon({
        image: require("../../../assets/location.png"),
        size: new window.AMap.Size(30, 32),
        imageSize: new window.AMap.Size(30, 32),
      });
      let marker = new window.AMap.Marker({
        position: [104.703034, 31.49376], //位置
        icon,
        offset: new window.AMap.Pixel(-15, -32),
      });
      this.map.add(marker);
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  width: 1200px;
  margin: 20px auto 40px;

  .in {
    display: flex;
  }

  .left {
    margin-right: 20px;
  }
  .right {
    flex-grow: 1;
  }

  .title {
    font-size: 30px;
    color: $theme;
    margin-bottom: 40px;
  }
  .desc {
    margin-bottom: 30px;

    .p {
      display: flex;
      align-items: center;
      margin-bottom: 10px;

      .icon {
        width: auto;
        height: 20px;
        margin-right: 10px;
      }
    }
    .phone-box {
      margin: 30px 0 40px;
      display: flex;

      .icon {
        width: 60px;
        height: 60px;
        margin-right: 20px;
      }
      .right {
        font-size: 24px;
        color: #999;

        span {
          font-weight: bold;
        }
      }
    }
  }
  .box {
    margin-bottom: 20px;
    border-bottom: 1px solid #eee;

    #container {
      width: 100%;
      height: 500px;
    }
    .info {
      margin: 30px 0;

      p {
        font-size: 18px;
      }
      .way {
        font-size: 20px;
        font-weight: bold;
        margin-top: 10px;
        margin-bottom: 5px;
      }
    }
  }
}

.m_types {
  display: none;
  padding-top: 10px;
}

@media (max-width: 1366px) {
  .content,
  .detail {
    width: 1000px;
  }
}

@media (max-width: 960px) {
  .types {
    display: none;
  }
  .m_types {
    display: block;
  }
  .content {
    width: 100%;
    margin: 0 0 20px;
    padding: 10px;
    box-sizing: border-box;

    .in {
      flex-direction: column;
    }

    .title {
      font-size: 20px;
    }
    .desc {
      .p {
        font-size: 14px;
      }
      .phone-box {
        .icon {
          width: 50px;
          height: 50px;
        }
        .right {
          font-size: 20px;
        }
      }
    }
    .box {
      #container {
        height: 60vh;
      }
      .info {
        p {
          font-size: 14px;
        }
        .way {
          font-size: 16px;
        }
      }
    }
  }
}
</style>
