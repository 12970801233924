<!--  -->
<template>
  <div class="classify baseMap">
    <div class="types">
      <div
        :class="{ type: true, active: activeSub == item.code }"
        v-for="item in subArr"
        :key="item.code"
        @click="changeSub(item)"
      >
        {{ item.name }}
      </div>
    </div>
    <van-tabs
      class="m_types"
      v-model="activeSub"
      color="#3ba86f"
      background="transparent"
      @change="mSubChange"
    >
      <van-tab
        :title="item.name"
        :name="item.code"
        v-for="item in subArr"
        :key="item.code"
      ></van-tab>
    </van-tabs>
    <div class="content">
      <div class="list-box" v-if="list.length > 0">
        <div class="list">
          <div
            class="item"
            v-for="item in list"
            :key="item.id"
            @click="getDetail(item)"
          >
            <div class="post">
              <img :src="URL + item.imgUrl" alt="" />
            </div>
            <div class="title">{{ item.title }}</div>
            <div class="subTitle" v-show="item.subTitle">
              {{ item.subTitle }}
            </div>
            <div
              class="desc"
              v-html="repalceHtmlToText(item.content).slice(0, 80)"
            ></div>
          </div>
          <div class="item"></div>
        </div>
        <el-empty
          description="暂无数据"
          :image-size="200"
          v-show="list.length == 0"
        ></el-empty>
        <el-pagination
          :hide-on-single-page="total <= 9"
          @current-change="currentChange"
          :current-page.sync="queryParams.pageNum"
          :page-size="queryParams.pageSize"
          layout="total, prev, pager, next"
          :total="total"
        >
        </el-pagination>
      </div>

      <div class="rich" v-else>
        <div class="detail" v-show="!!list[0]"></div>
        <el-empty
          description="暂无数据"
          :image-size="200"
          v-show="list.length == 0"
        ></el-empty>
      </div>
    </div>
  </div>
</template>

<script>
import { URL } from "@/util/config.js";

export default {
  data() {
    return {
      URL: URL,
      subArr: [
        {
          name: "云上展厅",
          code: "YUNSHANGZHANTING",
          path: "cloudNew/list",
        },
        {
          name: "飞行影院",
          code: "FEIXINGYINGYUAN",
          path: "flyNew/list",
        },
      ],
      activeSub: "YUNSHANGZHANTING",
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        publishFlag: 1,
        typeCode: "",
      }, // 新闻内容查询参数
      list: [], // 新闻列表
      total: 0, // 总新闻
    };
  },
  created() {
    this.getList();
  },
  mounted() {},
  methods: {
    // 二级字典切换
    changeSub(item) {
      if (this.activeSub != item.code) {
        this.$router.push("/imgNew/" + item.path);
      }
    },
    mSubChange(val) {
      const item = this.subArr.find((ele) => ele.code == val);
      this.$router.push("/imgNew/" + item.path);
    },
    // 获取新闻列表
    getList() {
      this.queryParams.typeCode = this.activeSub;
      this.$api.sysNewsList(this.queryParams).then((res) => {
        this.list = res.rows;
        this.total = res.total;
      });
    },
    currentChange(val) {
      this.queryParams.pageNum = val;
      this.getList(this.queryParams);
    },
    getDetail(item) {
      this.$router.push("/newsDetail/" + item.id);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/style/color.scss";
.content {
  width: 1200px;
  min-height: 100px;
  margin: 20px auto 40px;
  box-shadow: 0 0 5px #ccc;
  border-radius: 4px;
  padding: 20px;
  box-sizing: border-box;
  background-color: #fff;

  .list {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .item {
      width: 49%;
      margin-bottom: 20px;
      padding-bottom: 5px;
      border-radius: 5px;
      transition: 0.5s;

      &:not(:empty):hover {
        cursor: pointer;
        box-shadow: 0 0 5px #ccc;
        transform: translateY(-6px);
      }

      .post {
        width: 100%;
        height: 320px;
        border-radius: 5px;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .title {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding: 6px 10px;
        font-weight: bold;
        color: #000;
      }
      .subTitle {
        font-size: 14px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding: 0 10px;
        color: #333;
      }

      .desc {
        padding: 2px 10px;
        font-size: 14px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    }
  }

  .rich {
    font-size: 14px;

    ::v-deep {
      img {
        width: 100%;
      }
    }
  }
}

.m_types {
  display: none;
  padding-top: 10px;
}

@media (max-width: 1366px) {
  .content {
    width: 1000px;

    .list .item .post {
      height: 270px;
    }
  }
}

@media (max-width: 960px) {
  .types {
    display: none;
  }

  .m_types {
    display: block;
  }

  .content {
    width: 100%;
    margin: 0;
    padding: 10px;
    box-shadow: none;

    .list .item {
      width: 100%;

      .post {
        height: 200px;
      }
    }
  }
}
</style>
