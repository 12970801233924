<!-- 悬浮图片 -->
<template>
  <div
    v-show="show"
    class="fixed"
    :style="{ left, top }"
    @mouseenter="pause"
    @mouseleave="goOn"
    @click="navTo()"
  >
    <img src="../assets/fixed.jpg" alt="fixed" class="bg" />
    <i class="icon el-icon-circle-close" @click.stop="close"></i>
  </div>
</template>

<script>
export default {
  data() {
    return {
      left: "",
      top: window.innerHeight / 2 + "px",
      direction: "down",
      timer: null,
      show: true,
    };
  },
  created() {},
  mounted() {
    this.goOn();
  },
  methods: {
    navTo() {
      this.$router.push("/service/reserve/index");
    },
    goOn() {
      if (!this.show) return;
      let floatingBox = document.getElementsByClassName("fixed")[0];
      let position = {
        top: floatingBox.offsetTop,
        left: floatingBox.offsetLeft,
      };
      const leftStep = 10;
      const topStep = 5;

      this.timer = setInterval(() => {
        switch (this.direction) {
          case "right":
            position.left += leftStep;
            position.top -= topStep;
            if (position.left + floatingBox.offsetWidth >= window.innerWidth) {
              this.direction = "up";
            }
            break;
          case "down":
            position.left += leftStep;
            position.top += topStep;
            if (position.top + floatingBox.offsetHeight >= window.innerHeight) {
              this.direction = "right";
            }
            break;
          case "left":
            position.left -= leftStep;
            position.top += topStep;
            if (position.left <= 0) {
              this.direction = "down";
            }
            break;
          case "up":
            position.left -= leftStep;
            position.top -= topStep;
            if (position.top <= 0) {
              this.direction = "left";
            }
            break;
        }

        this.top = position.top + "px";
        this.left = position.left + "px";
      }, 100);
    },
    pause() {
      clearInterval(this.timer);
    },
    close() {
      this.show = false;
      clearInterval(this.timer);
    },
  },
  beforeDestroy() {
    this.timer && clearInterval(this.timer);
  },
};
</script>

<style lang='scss' scoped>
.fixed {
  width: fit-content;
  position: fixed;
  z-index: 1001;
  transition: all 0.5s;
  cursor: pointer;

  .bg {
    width: 200px;
    height: auto;
    border-radius: 10px;
  }

  .icon {
    color: #fff;
    font-size: 20px;
    position: absolute;
    right: 5px;
    top: 5px;
    cursor: pointer;
  }
}

@media (max-width: 750px) {
  .fixed {
    display: none !important;
  }
}
</style>
