import axios from "axios";
// const baseUrl = "http://192.168.40.147:8080/"; //生产地址
const baseUrl = "api/"; //生产地址
// const baseUrl = "https://www.mycsghzlg.com/api/"; //生产地址
export const ip = baseUrl; //生产地址
// export const ip = process.env.NODE_ENV == "development" ? "/api" : baseUrl; //生产地址
export const codeURL = ip + "index/loginAdmin/getVerify"; //登录图形验证码
export const URL = "https://www.mycsghzlg.com/api/"; //城建图片地址前缀
export const uploadURL = ip + "web/file/upload"; //上传地址
//富文本框文件上传配置
export const uploadConfig = {
  action: "", // 必填参数 图片上传地址
  methods: "", // 必填参数 图片上传方式
  token: "", // 可选参数 如果需要token验证，假设你的token有存放在sessionStorage
  name: "img", // 必填参数 文件的参数名
  size: 500, // 可选参数   图片大小，单位为Kb, 1M = 1024Kb
  accept: "image/png, image/gif, image/jpeg, image/bmp, image/x-icon", // 可选 可上传的图片格式
};
// 工具栏配置
export const toolbarOptions = [
  ["bold", "italic", "underline", "strike"], // toggled buttons
  ["blockquote", "code-block"],

  [{ header: 1 }, { header: 2 }], // custom button values
  [{ list: "ordered" }, { list: "bullet" }],
  [{ script: "sub" }, { script: "super" }], // superscript/subscript
  [{ indent: "-1" }, { indent: "+1" }], // outdent/indents
  [{ direction: "rtl" }], // text direction

  [{ size: ["small", false, "large", "huge"] }], // custom dropdown
  [{ header: [1, 2, 3, 4, 5, 6, false] }],

  [{ color: [] }, { background: [] }], // dropdown with defaults from theme
  [{ font: [] }],
  [{ align: [] }],
  ["clean"], // remove formatting button
  ["link", "image", "video"],
];

export const handlers = {
  //重写图片上传
  image: function image() {
    let self = this;
    let fileInput = this.container.querySelector("input.ql-image[type=file]");
    if (fileInput === null) {
      fileInput = document.createElement("input");
      fileInput.setAttribute("type", "file");
      // 设置图片参数名
      if (uploadConfig.name) {
        fileInput.setAttribute("name", uploadConfig.name);
      }
      // 可设置上传图片的格式
      fileInput.setAttribute("accept", uploadConfig.accept);
      fileInput.classList.add("ql-image");
      // 监听选择文件
      fileInput.addEventListener("change", function () {
        let params = new FormData();
        params.append("file", fileInput.files[0]);
        axios
          .post(uploadURL, params, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then(function (res) {
            if (res.status == 200) {
              let length = self.quill.getSelection(true).index;
              //写入图片
              self.quill.insertEmbed(length, "image", URL + res.data.data);
              self.quill.setSelection(length + 1);
            } else {
              self.$message.error(res.msg);
            }
            fileInput.value = "";
          });
      });
      this.container.appendChild(fileInput);
    }
    fileInput.click();
  },
};
