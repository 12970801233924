import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import "vant/lib/index.css";
import VideoPlayer from "vue-video-player";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import Dayjs from "dayjs";

import {
  NavBar,
  Tab,
  Tabs,
  NoticeBar,
  Swipe,
  SwipeItem,
  Icon,
  Popup,
  Tabbar,
  TabbarItem,
} from "vant";

Vue.use(NavBar);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Tabbar);
Vue.use(TabbarItem);
Vue.use(Popup);
Vue.use(Icon);
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(NoticeBar);
require("video.js/dist/video-js.css");
require("vue-video-player/src/custom-theme.css");

import api from "./util/api";
import { repalceHtmlToText } from "@/util/strUtill";
Vue.prototype.$api = api;
Vue.prototype.$dayjs = Dayjs;
Vue.prototype.repalceHtmlToText = repalceHtmlToText;
Vue.config.productionTip = false;
Vue.use(ElementUI);
Vue.use(VideoPlayer);

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
