<!-- 语音讲解 -->
<template>
  <div class="classify baseMap">
    <div class="types">
      <div
        :class="{ type: true, active: activeSub == item.code }"
        v-for="item in subArr"
        :key="item.code"
        @click="changeSub(item)"
      >
        {{ item.name }}
      </div>
    </div>
    <van-tabs
      class="m_types"
      v-model="activeSub"
      color="#3ba86f"
      background="transparent"
      @change="mSubChange"
    >
      <van-tab
        :title="item.name"
        :name="item.code"
        v-for="item in subArr"
        :key="item.code"
      ></van-tab>
    </van-tabs>
    <div class="content">
      <div class="list-box" v-if="list.length > 0">
        <div class="list">
          <div class="item" v-for="item in list" :key="item.id">
            <div class="title">
              <div class="one">{{ item.title }}</div>
              <div class="two" v-show="item.subTitle">{{ item.subTitle }}</div>
            </div>
            <div class="post">
              <img :src="URL + item.imgUrl" alt="" />
            </div>
            <div class="desc" v-html="item.content"></div>
            <div class="source">
              <!-- controlsList="nodownload" -->
              <audio
                :ref="'audio' + item.id"
                :src="URL + item.otherUrl"
                controls
                oncontextmenu="return false"
                @play="audioPlay(item.id)"
                @ended="audioEnd"
              ></audio>
            </div>
          </div>
        </div>
        <el-empty
          description="暂无内容"
          :image-size="200"
          v-show="list.length == 0"
        ></el-empty>
      </div>

      <div class="rich" v-else>
        <div class="detail" v-show="!!list[0]"></div>
        <el-empty
          description="暂无数据"
          :image-size="200"
          v-show="list.length == 0"
        ></el-empty>
      </div>
    </div>
  </div>
</template>

<script>
import { URL } from "@/util/config.js";

export default {
  data() {
    return {
      URL: URL,
      subArr: [
        {
          name: "入馆须知",
          code: "RUGUANXUZHI",
          path: "/ushouldknow",
        },
        {
          name: "在线预约",
          code: "ZAIXIANYUYUE",
          path: "/reserve/index",
        },
        {
          name: "参观路线",
          code: "CANGUANLUXIAN",
          path: "/line/index",
        },
        {
          name: "交通信息",
          code: "JIAOTONGXINXI",
          path: "/traffic/index",
        },
        {
          name: "语音讲解",
          code: "YUYINJIANGJIE",
          path: "/voice/index",
        },
      ],
      activeSub: "YUYINJIANGJIE",
      queryParams: {
        pageIndex: 1,
        pageSize: 10,
        publishFlag: 1,
        typeCode: "",
      }, // 新闻内容查询参数
      list: [], // 新闻列表
      total: 0, // 总新闻
      playingAudioId: null, // 同时只能有一个音频被播放
    };
  },
  created() {
    this.getList();
  },
  mounted() {},
  methods: {
    // 二级字典切换
    changeSub(item) {
      if (this.activeSub != item.code) {
        this.$router.push("/service" + item.path);
      }
      this.activeSub = item.code;
      this.getList();
    },
    mSubChange(val) {
      const item = this.subArr.find((ele) => ele.code == val);
      this.$router.push("/service" + item.path);
    },
    // 获取新闻列表
    getList() {
      this.queryParams.typeCode = this.activeSub;
      this.$api.sysNewsList(this.queryParams).then((res) => {
        this.list = res.rows;
        this.total = res.total;
      });
    },
    getDetail(item) {
      this.$router.push("/newsDetail/" + item.id);
    },
    audioPlay(id) {
      if (this.playingAudioId && this.playingAudioId != id) {
        const audio = this.$refs["audio" + this.playingAudioId][0];
        audio.pause();
      }
      this.playingAudioId = id;
    },
    audioEnd() {
      this.playingAudioId = null;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/style/color.scss";

audio::-webkit-media-controls {
  overflow: hidden !important;
}
audio::-webkit-media-controls-enclosure {
  width: calc(100% + 32px);
  margin-left: auto;
}
.content {
  width: 1200px;
  min-height: 100px;
  margin: 20px auto 40px;
  box-shadow: 0 0 5px #ccc;
  border-radius: 10px;
  padding: 20px;
  box-sizing: border-box;
  background-color: #fff;

  .list {
    .item {
      width: 100%;
      margin-bottom: 20px;
      padding-bottom: 5px;
      border-radius: 5px;
      transition: 0.5s;

      .post {
        width: 100%;
        height: auto;
        border-radius: 5px;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .title {
        color: #333;
        text-align: center;
        padding: 16px 10px;
        font-size: 20px;

        .two {
          color: #666;
          font-size: 17px;
          margin-top: 5px;
        }
      }

      .desc {
        padding: 2px 10px;
        font-size: 14px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }

      .source {
        text-align: center;
        margin-top: 10px;
      }
      audio {
        width: 80%;
        border-radius: 30px;
      }
    }
  }

  .rich {
    font-size: 14px;

    ::v-deep {
      img {
        width: 100%;
      }
    }
  }
}

.m_types {
  display: none;
  padding-top: 10px;
}

@media (max-width: 1366px) {
  .content {
    width: 1000px;
  }
}

@media (max-width: 960px) {
  .types {
    display: none;
  }

  .m_types {
    display: block;
  }

  .content {
    width: 100%;
    margin: 0;
    padding: 10px;
    box-shadow: none;

    .list .item {
      width: 100%;
    }
  }
}
</style>
