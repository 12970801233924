<template>
  <div class="frame">
    <!-- body 部分 -->
    <Top></Top>
    <fix-box></fix-box>
    <router-view :key="$route.params.parentCode" />
    <Foot></Foot>
  </div>
</template>

<script>
import Top from "@/components/top.vue";
import Foot from "@/components/foot.vue";
import FixBox from "../components/fixBox.vue";
export default {
  data() {
    return {
      show: false,
    };
  },
  components: {
    Top,
    Foot,
    FixBox,
  },
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.frame {
  width: 100vw;
  overflow-x: hidden;
}
.frame-body {
  min-height: calc(100vh - 130px - 90px);
}
.m-st-nav {
  display: none;
  width: 250px;
  height: 100vh;
  background-color: #f1eaec;
  box-shadow: inset -4px 0 17px 0 rgba(0, 0, 0, 0.35);
  position: fixed;
  left: 0;
  top: 0;

  .top {
    color: #fff;
    background-color: #cc0f16;
    padding: 20px 18px;
    font-size: 0.28rem;

    .name {
      margin-bottom: 2px;
      font-weight: bold;
    }
    .text {
      font-size: 0.27rem;
      font-weight: 100;
      opacity: 0.6;
    }
  }
  .nav {
    font-size: 0.28rem;
    .list {
      padding: 15px 20px;
      color: #666666;
      border-bottom: 1px solid;
      border-color: rgba($color: #fff, $alpha: 0.5);
    }
    .on {
      color: $theme;
    }
  }
}
.st-body {
  transition: all 0.3s;
  position: relative;
  background-color: #ffffff;
  &::before {
    content: "";
    display: table;
  }

  .white {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 10;
    background-color: rgba($color: #ffffff, $alpha: 0.5);
  }
}
.translate {
  transform: translateX(250px);
}
@media (max-width: 1024px) {
  .m-st-nav {
    display: block;
  }
}
</style>
