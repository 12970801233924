import request from "../request";
/**
 *  获取所有的权限
 * @method getAllPermission
 * @return {[type]}         [description]
 */
const baseUrl='/wx/web/webBanner'

//查询已发布的banner列表
export function sysBannerList(form) {
  return request({
    url: baseUrl+`/list`,
    method: "get",
    params: form,
  });
}

export default {
  sysBannerList,
};
