<template>
  <div class="reserve classify baseMap">
    <div class="types">
      <div
        :class="{ type: true, active: activeSub == item.code }"
        v-for="item in subArr"
        :key="item.code"
        @click="changeSub(item)"
      >
        {{ item.name }}
      </div>
    </div>
    <van-tabs
      class="m_types"
      v-model="activeSub"
      color="#3ba86f"
      background="transparent"
      @change="mSubChange"
    >
      <van-tab
        :title="item.name"
        :name="item.code"
        v-for="item in subArr"
        :key="item.code"
      ></van-tab>
    </van-tabs>
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <div style="text-align: center">{{ date }}</div>
      </div>
      <el-form inline :rules="rules" ref="form" :model="form">
        <el-card style="margin-top: 20px" shadow="never" header="选择展馆">
          <div class="lib-list">
            <div
              class="lib"
              :class="roomIndex == index ? 'lib-active' : ''"
              v-for="(item, index) in roomList"
              :key="index"
              @click="changeRoom(index, item.hallCode)"
            >
              {{ item.hallName }}
            </div>
          </div>
        </el-card>

        <el-card style="margin-top: 20px" shadow="never" header="选择日期">
          <div class="date-list">
            <div
              class="date-info"
              v-for="(item, index) in dateList"
              :key="index"
              @click="getAppointList(index)"
            >
              <div :class="dateIndex == index ? 'label-active' : ''">
                {{ item.weekStr }}
              </div>
              <div
                class="info"
                :class="dateIndex == index ? 'info-active' : ''"
              >
                <div class="date">{{ item.dateStr }}</div>
                <div class="num">余票：{{ item.couldNum }}</div>
              </div>
            </div>
          </div>
        </el-card>
        <el-card
          style="margin-top: 20px"
          shadow="never"
          header="选择入馆时间段"
        >
          <div class="time-list">
            <div
              class="time"
              :class="appointIndex == index ? 'time-active' : ''"
              v-for="(item, index) in appointList"
              :key="index"
              @click="chooseTime(item, index)"
            >
              {{ item.remark }}（余票:{{ item.totalNum - item.actuallyNum }}）
            </div>
          </div>
        </el-card>

        <el-card style="margin-top: 20px" shadow="never" header="参观者信息()">
          <template #header>
            <div>
              参观者信息<span class="tip"
                ><i class="el-icon-warning-outline"></i
                >个人预约总人数不得超过4人；团体预约总人数须在5-50人之间</span
              >
            </div>
          </template>
          <el-form
            label-width="100px"
            :label-position="isMobile ? 'left' : 'right'"
            :inline="true"
          >
            <el-form-item label="预约类型" prop="appointmentType">
              <el-select class="ipt" v-model="form.appointmentType">
                <el-option
                  v-for="item in typeArr"
                  :label="item.dictLabel"
                  :value="item.dictValue"
                  :key="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="联系人" prop="linkUserName">
              <el-input class="ipt" v-model="form.linkUserName"></el-input>
            </el-form-item>
            <el-form-item label="联系人身份证" prop="idCardNo">
              <el-input class="ipt" v-model="form.idCardNo"></el-input>
            </el-form-item>
            <el-form-item label="手机号码" prop="phoneNum">
              <el-input
                class="ipt"
                type="tel"
                v-model="form.phoneNum"
              ></el-input>
            </el-form-item>
            <el-form-item label="短信验证码" prop="code">
              <el-input
                class="ipt"
                type="number"
                v-model="form.code"
              ></el-input>
            </el-form-item>
            <div class="type-title">
              <span>同行人信息</span>
              <el-button
                class="addBtn"
                type="primary"
                size="mini"
                icon="el-icon-plus"
                @click="addUser"
                >添加同行人</el-button
              >
            </div>
            <div class="members">
              <div
                class="member"
                v-for="(item, idx) in userList"
                :key="item.id"
              >
                <div class="label">成员{{ idx + 1 }}</div>
                <div class="val">
                  <el-input
                    v-model="item.linkUserName"
                    class="ipt"
                    type="tel"
                    placeholder="请输入用户名称"
                  ></el-input>
                  <div class="line">-</div>
                  <el-input
                    v-model="item.idCardNo"
                    class="ipt"
                    placeholder="请输入身份证号"
                  ></el-input>
                  <i class="el-icon-remove-outline" @click="delUser(idx)"></i>
                </div>
              </div>
            </div>
          </el-form>
        </el-card>
        <div style="width: 100%; text-align: center; margin-top: 20px">
          <el-button style="width: 120px" type="success" @click="sendSms"
            >发送验证码</el-button
          >
          <el-button style="width: 120px" type="success" @click="onSubmit"
            >立即预约</el-button
          >
        </div>
      </el-form>
    </el-card>
  </div>
</template>
<script>
export default {
  data() {
    return {
      subArr: [
        {
          name: "入馆须知",
          code: "RUGUANXUZHI",
          path: "/ushouldknow",
        },
        {
          name: "在线预约",
          code: "ZAIXIANYUYUE",
          path: "/reserve/index",
        },
        {
          name: "参观路线",
          code: "CANGUANLUXIAN",
          path: "/line/index",
        },
        {
          name: "交通信息",
          code: "JIAOTONGXINXI",
          path: "/traffic/index",
        },
        {
          name: "语音讲解",
          code: "YUYINJIANGJIE",
          path: "/voice/index",
        },
      ],
      activeSub: "ZAIXIANYUYUE",
      query: {
        roomCode: "",
      },
      roomList: [],
      date: this.$dayjs().format("YYYY年M月"),
      dateList: [],
      dateIndex: undefined,
      appointList: [],
      appointIndex: undefined,
      roomIndex: -1,
      timeIndex: -1,
      typeArr: [],
      rules: {
        dateId: { required: true, message: "请选择一个场次", trigger: "blur" },
        phoneNum: {
          required: true,
          message: "手机号码不能为空",
          trigger: "blur",
        },
        idCardNo: {
          required: true,
          message: "身份证类型不能为空",
          trigger: "change",
        },
        totalNum: {
          required: true,
          message: "总人数不能为空",
          trigger: "blur",
        },
        appointmentType: {
          required: true,
          message: "预约类型不能为空",
          trigger: "blur",
        },
        linkUserName: {
          required: true,
          message: "联系人不能为空",
          trigger: "change",
        },
        code: {
          required: true,
          message: "短信验证码不能为空",
          trigger: "change",
        },
      },
      form: {
        appointmentDetailsUserList: [],
        dateId: undefined,
        phoneNum: "",
        idCardNo: "",
        totalNum: 1,
        appointmentType: "",
        linkUserName: "",
        code: "",
      },
      userList: [], // 参观所有人数组
      isMobile: false,
    };
  },
  created() {
    this.listRoom();
    this.listAppointType();
    this.isMobile = sessionStorage.getItem("isMobile") == 1;
  },
  methods: {
    changeSub(item) {
      if (this.activeSub != item.code) {
        this.$router.push("/service" + item.path);
      }
    },
    mSubChange(val) {
      const item = this.subArr.find((ele) => ele.code == val);
      this.$router.push("/service" + item.path);
    },
    chooseTime(item, index) {
      if (item.status != "COULD") {
        this.$message.error("当前状态不能预约");
        return false;
      }
      this.appointIndex = index;
      this.form.dateId = item.id;
    },
    changeRoom(index, code) {
      this.query.roomCode = "";
      this.roomIndex = index;
      this.query.roomCode = code;
      this.listAppointment();
    },
    listAppointType() {
      this.$api.listAppointType().then((res) => {
        if (res.code === 200) {
          this.typeArr = res.data;
        }
      });
    },
    listRoom() {
      this.$api.listDictByType({}).then((res) => {
        if (res.code === 200) {
          this.roomList = res.data;
          if (this.roomList && this.roomList.length > 0) {
            this.query.roomCode = this.roomList[0].hallCode;
            this.roomIndex = 0;
            this.listAppointment();
          }
        }
      });
    },
    sendSms() {
      if (!this.form.phoneNum) {
        this.$message.error("请先输入手机号");
        return false;
      }
      this.$api
        .sendCode({
          phoneNum: this.form.phoneNum,
        })
        .then((res) => {
          if (res.code === 200) {
            this.$message.success("短信发送成功");
          }
        });
    },
    // 获取日期数据
    listAppointment() {
      this.$api.appointmentList(this.query).then((res) => {
        if (res.code === 200) {
          this.dateList = res.data;
          if (this.dateList && this.dateList.length > 0) {
            this.getAppointList(0);
          }
        }
      });
    },
    // 获取预约时间段
    getAppointList(index) {
      this.dateIndex = index;
      this.appointIndex = 0;
      this.appointList = this.dateList[index].dateList;
      this.form.dateId = this.appointList[0] && this.appointList[0].id;
    },
    dayNumToText(day) {
      if (day > 6) day = day - 7;
      let str = "";
      switch (day) {
        case 1:
          str = "一";
          break;
        case 2:
          str = "二";
          break;
        case 3:
          str = "三";
          break;
        case 4:
          str = "四";
          break;
        case 5:
          str = "五";
          break;
        case 6:
          str = "六";
          break;
        case 0:
          str = "天";
          break;
      }
      return str;
    },
    // 添加同行人
    addUser() {
      let lastId = 0;
      if (this.userList.length != 0) {
        lastId = this.userList[this.userList.length - 1].id;
      }
      this.userList.push({ id: lastId + 1, idCardNo: "", linkUserName: "" });
    },
    // 删除同行人
    delUser(idx) {
      this.userList.splice(idx, 1);
    },
    onSubmit() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          // if(this.userList.length == 1 && )
          const noBlank = this.userList.every((ele) => {
            return ele.linkUserName && ele.idCardNo;
          }); // 检测同行人信息是否存在空白
          if (!noBlank) {
            this.$message.error("请核查同行人信息是否填写完整!");
            return;
          }
          // 格式处理
          this.form.totalNum = this.userList.length + 1; // 总人数
          let { linkUserName, idCardNo, phoneNum } = this.form;
          const firstUser = [{ linkUserName, idCardNo, phoneNum }]; // 联系人
          this.form.appointmentDetailsUserList = firstUser.concat(
            this.userList
          ); // 联系人 + 同行人
          // 发起请求
          this.$api.submitForm(this.form).then((res) => {
            if (res.code === 200) {
              if (this.form.appointmentType == "TEAM") {
                // 团队-提示待审核
                this.$alert(
                  "接下来我们会与您进行电话沟通，请留意",
                  "预约提交成功!",
                  {
                    type: "success",
                    confirmButtonText: "我已了解",
                    callback: () => {
                      this.$router.go(0);
                    },
                  }
                );
                return;
              }
              this.$message.success({ message: "预约成功", duration: 5000 });
              setTimeout(() => {
                location.reload();
              }, 5000);
            }
          });
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.ipt {
  width: 180px;
}
.reserve {
  .box-card {
    width: 1200px;
    margin: 20px auto;

    .type-title {
      margin-bottom: 10px;
      padding-top: 10px;
      border-top: 1px solid #eee;
    }
    .members {
      .member {
        display: flex;
        align-items: center;
        margin-bottom: 10px;

        .label {
          width: 100px;
          text-align: right;
          font-size: 14px;
          color: #606266;
          padding-right: 12px;
          box-sizing: border-box;
        }
        .val {
          display: flex;
          align-items: center;

          .line {
            margin: 0 5px;
          }
          i {
            font-size: 25px;
            margin-left: 10px;
            cursor: pointer;

            &:hover {
              color: $theme;
            }
          }
        }
      }
    }
  }

  .date-list {
    display: flex;
    // justify-content: space-between;

    .date-info {
      text-align: center;
      cursor: pointer;
      margin-right: 20px;

      .label-active {
        color: #5ba574;
      }

      .info {
        padding: 10px 4px;
        border: 1px solid #5ba574;
        border-radius: 5px;
        margin-top: 10px;

        .num {
          font-size: 15px;
        }
      }

      .info-active {
        background-color: #5ba574;
        color: #ffffff;
      }
    }
  }

  .lib-list {
    display: flex;
    flex-wrap: wrap;

    .lib {
      padding: 5px;
      border: 1px solid #5ba574;
      border-radius: 5px;
      margin-right: 20px;
      margin-bottom: 10px;
      cursor: pointer;
    }

    .lib-active {
      background-color: #5ba574;
      color: #ffffff;
    }
  }
  .time-list {
    display: flex;
    flex-wrap: wrap;
    // justify-content: space-between;

    .time {
      width: 152px;
      font-size: 14px;
      padding: 10px 5px;
      text-align: center;
      border: 1px solid #5ba574;
      border-radius: 5px;
      margin-right: 20px;
      margin-bottom: 10px;
      cursor: pointer;

      &:last-child {
        margin-right: 0;
      }
    }

    .time-active {
      background-color: #5ba574;
      color: #ffffff;
    }
  }
}

.m_types {
  display: none;
  padding-top: 10px;
}

@media (max-width: 1366px) {
  .reserve {
    .box-card {
      width: 1000px;
    }

    .time-list .time {
      line-height: 30px;
      padding: 5px;
    }
  }
}

@media (max-width: 960px) {
  .reserve {
    .types {
      display: none;
    }
    .m_types {
      display: block;
    }
    .box-card {
      width: 100%;

      .members .member {
        .label {
          width: 25%;
          flex-shrink: 0;
        }
        .val {
          flex-wrap: wrap;

          .line + .ipt {
            margin-top: 5px;
          }
          i {
            margin-left: 5px;
          }
        }
      }
    }
    .date-list {
      overflow: auto;

      .date-info {
        margin-right: 0;

        & + .date-info {
          margin-left: 10px;
        }
        .info {
          white-space: nowrap;
        }
      }
    }
    .time-list {
      .time {
        width: 45%;
        margin-right: 5px;
      }
    }
  }
}
.tip {
  font-size: 14px;
  color: $theme;
  margin-left: 10px;

  i {
    font-size: 18px;
    margin-right: 5px;
  }
}
.addBtn {
  margin-left: 10px;
  background-color: $theme;
  border-color: $theme;

  &:hover,
  &:focus {
    background: $theme;
    border-color: $theme;
    opacity: 0.9;
  }
  &:active {
    transform: scale(0.9);
  }
}
</style>
