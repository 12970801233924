<!--  -->
<template>
  <div class="classify baseMap">
    <div class="types">
      <div
        :class="{ type: true, active: activeSub == item.code }"
        v-for="item in subArr"
        :key="item.code"
        @click="changeSub(item)"
      >
        {{ item.name }}
      </div>
    </div>
    <van-tabs
      class="m_types"
      v-model="activeSub"
      color="#3ba86f"
      background="transparent"
      @change="mSubChange"
    >
      <van-tab
        :title="item.name"
        :name="item.code"
        v-for="item in subArr"
        :key="item.code"
      ></van-tab>
    </van-tabs>
    <div class="content">
      <div class="list-box" v-if="list.length > 0">
        <div class="card" v-for="item in list" :key="item.id">
          <div class="subTitle" v-show="item.subTitle">{{ item.subTitle }}</div>
          <img :src="URL + item.imgUrl" :alt="item.title" />
        </div>
        <el-empty
          description="暂无数据"
          :image-size="200"
          v-show="list.length == 0"
        ></el-empty>
        <el-pagination
          :hide-on-single-page="total <= 9"
          @current-change="currentChange"
          :current-page.sync="queryParams.pageNum"
          :page-size="queryParams.pageSize"
          layout="total, prev, pager, next"
          :total="total"
        >
        </el-pagination>
      </div>

      <div class="rich" v-else>
        <div class="detail" v-show="!!list[0]"></div>
        <el-empty
          description="暂无数据"
          :image-size="200"
          v-show="list.length == 0"
        ></el-empty>
      </div>
    </div>
  </div>
</template>

<script>
import { URL } from "@/util/config.js";

export default {
  data() {
    return {
      URL: URL,
      subArr: [
        {
          name: "联系方式",
          code: "LIANXIFANGSHI",
        },
        {
          name: "员工风采",
          code: "YUANGONGFENGCAI",
        },
      ],
      activeSub: "YUANGONGFENGCAI",
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        publishFlag: 1,
        typeCode: "",
      }, // 新闻内容查询参数
      list: [], // 新闻列表
      total: 0, // 总新闻
    };
  },
  created() {
    this.getList();
  },
  mounted() {},
  methods: {
    // 二级字典切换
    changeSub(item) {
      switch (item.code) {
        case "LIANXIFANGSHI":
          this.$router.push({
            path: "/aboutUs/concatUs/index",
          });
          break;
      }
      this.activeSub = item.code;
      this.getList();
    },
    mSubChange(code) {
      if (code == "LIANXIFANGSHI") {
        this.$router.push("/aboutUs/concatUs/index");
      }
    },
    // 获取新闻列表
    getList() {
      this.queryParams.typeCode = this.activeSub;
      this.$api.sysNewsList(this.queryParams).then((res) => {
        this.list = res.rows;
        this.total = res.total;
      });
    },
    currentChange(val) {
      this.queryParams.pageNum = val;
      this.getList();
    },
    getDetail(item) {
      this.$router.push("/newsDetail/" + item.id);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/style/color.scss";
.content {
  width: 1200px;
  min-height: 100px;
  margin: 0 auto 40px;
  background-color: #fff;
  padding: 20px;
  box-sizing: border-box;

  .list-box .card {
    .subTitle {
      font-size: 16px;
      margin-bottom: 10px;
      text-align: center;
    }
    img {
      width: 100%;
      height: auto;
      border-bottom: 1px solid #eee;
      padding-bottom: 20px;
      margin-bottom: 20px;
    }
  }

  .el-card {
    margin-bottom: 30px;
    transition: transform 0.5s;

    &:hover {
      transform: translateY(-10px);
    }
  }

  .box-info {
    display: flex;
    cursor: pointer;

    &:hover {
      .left {
        .top {
          .title {
            color: $theme;
          }
        }
      }
      .right img {
        transform: scale(1.1);
      }
    }

    .left {
      flex-grow: 1;
      margin-right: 10px;

      .top {
        padding-bottom: 10px;
        display: flex;
        align-items: center;
        border-bottom: 1px solid #eee;

        .time {
          font-size: 14px;
          color: #999;
          text-align: center;
          padding-right: 10px;
          margin-right: 10px;
          border-right: 1px solid #eee;

          span {
            font-size: 18px;
            color: #333;
          }
        }
        .title {
          max-width: 500px;
          font-size: 20px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
      .desc {
        text-indent: 2em;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        margin: 10px 0;
      }
    }
    .right {
      width: 400px;
      height: 200px;
      flex-shrink: 0;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: transform 0.5s;
      }
    }
  }

  .list {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .item {
      width: 30%;
      margin-bottom: 20px;
      padding-bottom: 5px;
      border-radius: 5px;
      transition: 0.5s;

      &:not(:empty):hover {
        cursor: pointer;
        box-shadow: 0 0 5px #ccc;
        transform: translateY(-6px);
      }

      .post {
        width: 100%;
        height: 180px;
        border-radius: 5px;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .title {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding: 6px 10px;
      }

      .desc {
        padding: 2px 10px;
        font-size: 14px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    }
  }

  .rich {
    font-size: 14px;

    ::v-deep {
      img {
        width: 100%;
      }
    }
  }
}

.m_types {
  display: none;
  padding-top: 10px;
}

@media (max-width: 1366px) {
  .content,
  .detail {
    width: 1000px;
  }
  .content {
    .box-info .left .top .title {
      max-width: 300px;
    }
  }
}

@media (max-width: 960px) {
  .types {
    display: none;
  }

  .m_types {
    display: block;
  }

  .content,
  .detail {
    width: 100%;
    margin: 0;
    padding: 15px;
    box-shadow: none;
    box-sizing: border-box;

    .list .item {
      width: 100%;
    }
    .title {
      margin-top: 0;
      font-size: 20px;
    }
    .time {
      font-size: 14px;
      padding: 10px 0;
    }
  }
  .content {
    .box-info {
      display: block;

      .left {
        .top {
          .time {
            span {
              font-size: 16px;
            }
          }
          .title {
            font-size: 16px;
          }
        }
        .desc {
          display: none;
        }
      }
      .right {
        width: 100%;
        height: 170px;
      }
    }
  }
}
</style>
