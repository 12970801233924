<!-- 新闻详情 -->
<template>
  <div class="classify baseMap">
    <div class="types">
      <div
        :class="{ type: true, active: activeSub == item.code }"
        v-for="item in subArr"
        :key="item.code"
        @click="changeSub(item)"
      >
        {{ item.name }}
      </div>
    </div>
    <van-tabs
      class="m_types"
      v-model="activeSub"
      color="#3ba86f"
      background="transparent"
      @change="mSubChange"
    >
      <van-tab
        :title="item.name"
        :name="item.code"
        v-for="item in subArr"
        :key="item.code"
      ></van-tab>
    </van-tabs>
    <div class="content">
      <div class="list">
        <div
          class="item"
          v-for="item in list"
          :key="item.id"
          @click="getDetail(item)"
        >
          <div class="post">
            <img :src="URL + item.imgUrl" :alt="item.title" />
          </div>
          <div class="info">
            <div class="title">{{ item.title }}</div>
            <div class="subTitle" v-show="item.subTitle">
              {{ item.subTitle }}
            </div>
            <div class="in">{{ item.introduce }}</div>
            <div class="more">查看详情</div>
          </div>
        </div>
      </div>
      <el-pagination
        :hide-on-single-page="total <= 9"
        @current-change="currentChange"
        :current-page.sync="queryParams.pageNum"
        :page-size="queryParams.pageSize"
        layout="total, prev, pager, next"
        :total="total"
      >
      </el-pagination>
    </div>
    <!--    <div class="detail">-->
    <!--      <div class="title">{{ detail.title }}</div>-->
    <!--      <div class="time">{{ handleTime(detail.createTime) }}</div>-->
    <!--      <div class="rich" v-html="detail.content"></div>-->
    <!--    </div>-->
  </div>
</template>

<script>
import { URL } from "@/util/config.js";

export default {
  data() {
    return {
      URL: URL,
      activeSub: "LINSHIZHANLAN",
      subArr: [
        {
          name: "展馆概况",
          code: "ZHANGUANGAIKUANG",
          path: "guideIntroduce",
        },
        {
          name: "楼层导览",
          code: "LOUCENGDAOLAN",
          path: "floorIntroduce",
        },
        {
          name: "临时展厅",
          code: "LINSHIZHANLAN",
          path: "tempintroduce",
        },
      ],
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        publishFlag: 1,
        typeCode: "",
      }, // 新闻内容查询参数
      list: [], // 新闻列表
      total: 0, // 总新闻
    };
  },
  created() {
    this.getList();
  },
  mounted() {},
  methods: {
    // 二级字典切换
    changeSub(item) {
      switch (item.code) {
        case "ZHANGUANGAIKUANG":
          this.$router.push({
            path: "/guideList/guideIntroduce",
          });
          break;
        case "LOUCENGDAOLAN":
          this.$router.push({
            path: "/guideList/floorIntroduce",
          });
          break;
      }
      this.activeSub = item.code;
      this.getList();
    },
    mSubChange(val) {
      const item = this.subArr.find((ele) => ele.code == val);
      this.$router.push("/guideList/" + item.path);
    },
    // 获取新闻列表
    getList() {
      this.queryParams.typeCode = this.activeSub;
      this.$api.sysNewsList(this.queryParams).then((res) => {
        this.list = res.rows;
        this.total = res.total;
      });
    },
    currentChange(val) {
      this.queryParams.pageNum = val;
      this.getList();
    },
    getDetail(item) {
      this.$router.push("/guideList/tempIntroduce/" + item.id);
    },
  },
};
</script>

<style lang="scss" scoped>
.detail {
  width: 1200px;
  margin: 0 auto;
  min-height: 500px;
  text-align: center;
  color: #333;
  position: relative;
  .back {
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    cursor: pointer;
    &:hover {
      color: $theme;
      .icon {
        transform: rotate(0);
      }
    }

    .icon {
      width: 30px;
      transform: scale(0.9) rotate(30deg);
      transition: all 0.5s;
    }
    span {
      padding-left: 10px;
    }
  }

  .title {
    font-size: 30px;
    margin-top: 40px;
  }
  .time {
    color: #919797;
    padding: 20px 0;
  }

  .rich {
    text-align: left;
    ::v-deep img {
      width: 100%;
    }
  }
}

.content {
  width: 1200px;
  min-height: 100px;
  margin: 0 auto 40px;
  border-radius: 10px;
  padding: 20px;
  box-sizing: border-box;

  .list {
    .item {
      width: 100%;
      margin-bottom: 20px;
      padding: 20px;
      box-sizing: border-box;
      border-radius: 5px;
      transition: 0.5s;
      display: flex;
      background-color: #e7ecf1;
      border: 1px solid #e7ecf1;
      cursor: pointer;

      &:hover {
        transform: translateY(-10px);
        box-shadow: 0 0 5px #ccc;
      }

      &:nth-child(2n) {
        flex-direction: row-reverse;
        background-color: #fff;

        .info {
          margin-left: 0;
          margin-right: 20px;
        }
      }

      .post {
        width: 400px;
        height: 250px;
        border-radius: 5px;
        overflow: hidden;
        flex-shrink: 0;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .info {
        flex-grow: 1;
        margin-left: 20px;

        .title {
          max-width: 320px;
          font-weight: bold;
          font-size: 20px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .subTitle {
          font-size: 14px;
          margin-top: 5px;
        }
        .in {
          margin: 15px 0;
          white-space: pre-wrap;
          line-height: 1.8;
          font-size: 14px;

          .desc {
            display: flex;
            margin-bottom: 7px;

            .label {
              flex-shrink: 0;
            }
            .val {
              max-width: 320px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
        }
        .more {
          width: fit-content;
          font-size: 14px;
          color: $theme;
          cursor: pointer;
        }
      }
    }
  }

  .rich {
    font-size: 14px;

    ::v-deep {
      img {
        width: 100%;
      }
    }
  }
}

.m_types {
  display: none;
}

@media (max-width: 1366px) {
  .content,
  .detail {
    width: 1000px;
  }
}

@media (max-width: 960px) {
  .types {
    display: none;
  }
  .m_types {
    display: block;
    padding-top: 10px;
  }
  .content,
  .detail {
    width: 100%;
    margin: 0;
    padding: 10px;
    box-sizing: border-box;
    box-shadow: none;

    .title {
      margin-top: 0;
      font-size: 20px;
    }

    .list .item {
      display: block;
      background-color: #fff;
      padding: 0;

      .post {
        width: 100%;
        height: 210px;
      }
      .info {
        margin-top: 6px;
        padding: 5px;

        .in {
          margin: 10px 0;
        }
      }
    }
  }
}
</style>
